import { createSlice } from "@reduxjs/toolkit";
import { subYears, format } from 'date-fns';

const today = new Date();
const oneYearAgo = subYears(today, 1);

const initialState = {
    start_date: format(oneYearAgo, 'yyyy-MM-dd'),
    end_date: format(today, 'yyyy-MM-dd'),
};

const scoringSearchFilterSlice = createSlice({
    name: "scoringSearchFilter",
    initialState,
    reducers: {
        setStartDate: (state, action) => {
            state.start_date = action.payload;
        },
        setEndDate: (state, action) => {
            state.end_date = action.payload;
        },
        setDateRange: (state, action) => {
            const { start_date, end_date } = action.payload;
            state.start_date = start_date;
            state.end_date = end_date;
        },
    },
});

export const { setStartDate, setEndDate, setDateRange } = scoringSearchFilterSlice.actions;
export default scoringSearchFilterSlice.reducer;
