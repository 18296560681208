import React, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import { Box } from '@mui/material';
import styleTooltip from "../../DashPage/TestMapD/GeoChart.module.css";
import { formatNumber } from "../../../utils/countFunc";
import { useVirtualTooltipSize } from "../../../hook/useVirtualTooltipSize";
import styles from "../../DashPage/TestMapD/GeoChart.module.css";
import { format } from "date-fns";
import { ru } from 'date-fns/locale';

const calculateProgress = (stages, currentDate) => {
    const totalDuration = Date.parse(stages[stages.length - 1].endDate) - Date.parse(stages[0].startDate);
    const timePassed = currentDate - Date.parse(stages[0].startDate);
    return Math.min((timePassed / totalDuration) * 100, 100);
};

const StageProgressBar = ({ stages }) => {
    const [progress, setProgress] = useState(0);
    const ref = useRef();
    const tooltipRef = useRef(null);
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });

    useEffect(() => {
        setProgress(calculateProgress(stages, Date.now()));
    }, [stages]);

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const onMouseMove = (event, stage) => {
        const tooltipData = [
            { label: 'Этап', value: stage.name },
            { label: 'Дата начала', value: format(new Date(stage.startDate), 'dd.MM.yyyy', { locale: ru }) },
            { label: 'Дата окончания', value: format(new Date(stage.endDate), 'dd.MM.yyyy', { locale: ru }) }
        ];
        const tooltipSize = calculateTooltipSize(tooltipData);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }

        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        setTooltip({
            x,
            y,
            text: tooltipData
        });
    };

    const onMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: '' });
    };

    useEffect(() => {
        const svg = d3.select(ref.current);
        svg.selectAll("*").remove();

        const width = ref.current.clientWidth;
        const height = ref.current.clientHeight;
        const progressBarHeight = 4;
        const circleRadius = 5;
        const circleStrokeWidth = 2;

        svg.append('rect')
            .attr('x', circleRadius)
            .attr('y', (height / 2) - (progressBarHeight / 2))
            .attr('width', width - (circleRadius * 2))
            .attr('height', progressBarHeight)
            .attr('fill', '#EDF1F5');

        svg.append('rect')
            .attr('x', circleRadius)
            .attr('y', (height / 2) - (progressBarHeight / 2))
            .attr('width', 0)
            .attr('height', progressBarHeight)
            .attr('fill', '#4A3DFD')
            .transition()
            .duration(1000)
            .attr('width', Math.max(0, (width - (circleRadius * 2)) * (progress / 100)));

        stages.forEach((stage, index) => {
            const stageTime = Date.parse(stage.startDate);
            const isLast = index === stages.length - 1;
            const marginRight = isLast ? circleRadius + 20 : 0;
            const startX = isLast
                ? width - marginRight
                : circleRadius + (width - 2 * circleRadius) *
                ((stageTime - Date.parse(stages[0].startDate)) /
                    (Date.parse(stages[stages.length - 1].endDate) - Date.parse(stages[0].startDate)));

            svg.append('circle')
                .attr('cx', startX)
                .attr('cy', height / 2)
                .attr('r', circleRadius + circleStrokeWidth)
                .attr('fill', 'white')
                .on('mousemove', (event) => onMouseMove(event, stage))
                .on('mouseout', onMouseOut);

            svg.append('circle')
                .attr('cx', startX)
                .attr('cy', height / 2)
                .attr('r', circleRadius)
                .attr('fill', stageTime <= Date.now() ? '#4A3DFD' : '#EDF1F5')
                .on('mousemove', (event) => onMouseMove(event, stage))
                .on('mouseout', onMouseOut);
        });

        const firstStage = stages[0];
        const firstStageX = circleRadius + width * (
            (Date.parse(firstStage.startDate) - Date.parse(stages[0].startDate)) /
            (Date.parse(stages[stages.length - 1].endDate) - Date.parse(stages[0].startDate))
        );
        const firstStageTextLine1 = firstStage.name;
        const firstStageTextLine2 = format(new Date(firstStage.startDate), 'dd.MM.yyyy', { locale: ru });

        svg.append('text')
            .attr('y', height / 2 + 20)
            .attr('text-anchor', 'start')
            .attr('fill', 'black')
            .style('font-size', '10px')
            .append('tspan')
            .attr('x', firstStageX - 5)
            .attr('dy', '0.25em')
            .text(firstStageTextLine1)
            .attr('fill', '#8D96B2')
            .style('font-size', '12px')
            .append('tspan')
            .attr('x', firstStageX - 5)
            .attr('dy', '1.2em')
            .text(firstStageTextLine2)
            .attr('fill', '#1B2134')
            .style('font-size', '14px');

        const lastStage = stages.length === 4 ? stages[stages.length - 2] : stages[stages.length - 1];
        let lastStageX = circleRadius + width * (
            (Date.parse(lastStage.startDate) - Date.parse(stages[0].startDate)) /
            (Date.parse(stages[stages.length - 1].endDate) - Date.parse(stages[0].startDate))
        );

        const spacingThreshold = 300;
        if (lastStageX - firstStageX < spacingThreshold) {
            lastStageX = firstStageX + spacingThreshold;
        }

        const totalDays = stages.reduce((acc, stage) => {
            const startDate = new Date(stage.startDate);
            const endDate = new Date(stage.endDate);
            const diffTime = Math.abs(endDate - startDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return acc + diffDays;
        }, 0);

        const lastStageTextLine1 = "Срок исполнения контракта";
        const lastStageTextLine2 = `${totalDays} дней`;

        svg.append('text')
            .attr('x', lastStageX - 20)
            .attr('y', height / 2 + 20)
            .attr('text-anchor', 'middle')
            .attr('fill', 'black')
            .style('font-size', '10px')
            .append('tspan')
            .attr('x', lastStageX - 20)
            .attr('dy', '0.25em')
            .text(lastStageTextLine1)
            .attr('fill', '#8D96B2')
            .style('font-size', '12px')
            .append('tspan')
            .attr('x', lastStageX - 20)
            .attr('dy', '1.2em')
            .text(lastStageTextLine2)
            .attr('fill', '#1B2134')
            .style('font-size', '14px');

        // eslint-disable-next-line
    }, [stages, progress]);

    return (
        <Box sx={{ width: '100%', height: '81px', position: 'relative' }}>
            <svg ref={ref} width="100%" height="100%"></svg>
            {tooltip.text && (
                <div
                    ref={tooltipRef}
                    className={styleTooltip.tooltip}
                    style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                >
                    {tooltip.text.map(item => (
                        <div key={item.label}>
                            <strong>{item.label}</strong>: {formatNumber(item.value)}
                        </div>
                    ))}
                </div>
            )}
        </Box>
    );
};

export default StageProgressBar;
