import React from "react";
import style from "../ContractInfo/ContractInfo.module.css";
import StageProgressBar from "../../SearchPage/ProgressBar/ProgressBar";
import Spinner from "../../TestPages/Spinner";

const ContractInfo = ({ contractData }) => {
    if (!contractData) return <Spinner />;

    const {
        contract_reg_num,
        contract_name,
        contract_sign_date,
        contract_start_date,
        actually_paid,
        contract_price,
        placing_name,
        cust_region,
        status,
        stages: originalStages
    } = contractData;

    const stages =
        contractData.doc_publish_date &&
        contractData.bidding_date &&
        contractData.contract_sign_date &&
        contractData.gk_end_date
            ? [
                {
                    name: "Дата размещения извещения",
                    startDate: contractData.doc_publish_date,
                    endDate: contractData.bidding_date,
                },
                {
                    name: "Дата подведения итогов",
                    startDate: contractData.bidding_date,
                    endDate: contractData.contract_sign_date,
                },
                {
                    name: "Дата контракта",
                    startDate: contractData.contract_sign_date,
                    endDate: contractData.contract_sign_date,
                },
                {
                    name: "Дата окончания ГК",
                    startDate: contractData.gk_end_date,
                    endDate: contractData.gk_end_date,
                },
            ]
            : originalStages || [];

    return (
        <div className={style.mainContainer}>
            <div className={style.header}>
                <div className={style.mainTitle}>
                    <div className={style.title}>Контракт № {contract_reg_num}</div>
                    <div className={style.subTitle}>Извещение № {contract_reg_num}</div>
                </div>
                <div
                    className={style.status}
                    style={{
                        color:
                            status === "Исполнение завершено"
                                ? "green"
                                : status === "Исполнение прекращено"
                                    ? "#ec7411"
                                    : status === "Исполнение"
                                        ? "blue"
                                        : "#8D96B2",
                    }}
                >
                    {status}
                </div>
            </div>
            <div className={style.dataContainer}>
                <div className={style.infoBlock}>
                    <span className={style.infoLabel}>Объект закупки</span>
                    <span className={style.infoValue}>{contract_name}</span>
                </div>
                <div className={style.infoBlock}>
                    <span className={style.infoLabel}>Заказчик/Исполнитель</span>
                    <span className={style.infoValue}>{contractData.sup_full_name || "Не указано"}</span>
                </div>
                <div className={style.gridContainer}>
                    <div className={style.gridItem}>
                        <span className={style.infoLabel}>Дата размещения извещения</span>
                        <span className={style.infoValue}> {new Date(contract_start_date).toLocaleDateString("ru-RU")}</span>
                    </div>
                    <div className={style.gridItem}>
                        <span className={style.infoLabel}>Дата подписания контракта</span>
                        <span className={style.infoValue}> {new Date(contract_sign_date).toLocaleDateString("ru-RU")}</span>
                    </div>
                    <div className={style.gridItem}>
                        <span className={style.infoLabel}>Цена контракта</span>
                        <span className={style.infoValue}>{Number(contract_price).toLocaleString("ru-RU") || "Не указано"}</span>
                    </div>
                    <div className={style.gridItem}>
                        <span className={style.infoLabel}>Сумма исполнено/оплачено</span>
                        <span className={style.infoValue}>{actually_paid || "Не указано"}</span>
                    </div>
                    <div className={style.gridItem}>
                        <span className={style.infoLabel}>Регион заказчика/исполнителя</span>
                        <span className={style.infoValue}>{cust_region || "Не указано"}</span>
                    </div>
                    <div className={style.gridItem}>
                        <span className={style.infoLabel}>Способ определения</span>
                        <span className={style.infoValue}>{placing_name || "Не указано"}</span>
                    </div>
                </div>
                <div className={style.stagesContainer}>
                    <span className={style.headerText}>Подача заявок / Этапы</span>
                    <StageProgressBar stages={stages} />
                </div>
            </div>
        </div>
    );
};

export default ContractInfo;
