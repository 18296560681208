import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./StickyTable.module.css";
import Spinner from "../../TestPages/Spinner";
import { getRatingColor } from "../../../utils/colors";
import { fetchMonitoringData } from "../../../service/reducers/MonitoringSlice";
import Tooltip from "../../../components/Tooltip/Tooltip";
import useTooltip from "../../../hook/useTooltip";

const ContractNameCell = ({ contractName, selected }) => {
    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();
    return (
        <div
            className={styles.contractName}
            style={selected ? { color: "blue" } : {}}
            onMouseEnter={(e) =>
                showTooltip(e, [{ label: "Название", value: contractName }])
            }
            onMouseLeave={hideTooltip}
        >
            {contractName}
            <Tooltip
                ref={tooltipRef}
                x={tooltip.x}
                y={tooltip.y}
                text={tooltip.text}
                showColon={tooltip.showColon}
            />
        </div>
    );
};

const StickyTable = ({ onContractSelect, getQueryParams }) => {
    const dispatch = useDispatch();
    const { monitoringData, monitoringDataloading } = useSelector(
        (state) => state.monitoring
    );
    const contracts = useMemo(
        () => monitoringData?.data?.contracts || [],
        [monitoringData]
    );

    const [selectedRow, setSelectedRow] = useState(null);
    const tableScrollAreaRef = useRef(null);
    const [isFixedHidden, setIsFixedHidden] = useState(false);
    const DEFAULT_LIMIT = 100;
    const [hasMore, setHasMore] = useState(true);

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");

    const sortedContracts = useMemo(() => {
        if (!sortField) return contracts;
        const sorted = [...contracts].sort((a, b) => {
            let aVal, bVal;
            switch (sortField) {
                case "rating":
                    aVal = Number(a.rating);
                    bVal = Number(b.rating);
                    break;
                case "price":
                    aVal = Number(a.contract_price);
                    bVal = Number(b.contract_price);
                    break;
                case "date":
                    aVal = new Date(a.contract_sign_date).getTime();
                    bVal = new Date(b.contract_sign_date).getTime();
                    break;
                default:
                    return 0;
            }
            if (aVal < bVal) return sortOrder === "asc" ? -1 : 1;
            if (aVal > bVal) return sortOrder === "asc" ? 1 : -1;
            return 0;
        });
        return sorted;
    }, [contracts, sortField, sortOrder]);

    const onRowClick = useCallback(
        (contract) => {
            setSelectedRow(contract.contract_reg_num);
            onContractSelect && onContractSelect(contract);
        },
        [onContractSelect]
    );

    useEffect(() => {
        setSelectedRow(null);
        if (contracts.length > 0) onRowClick(contracts[0]);
    }, [contracts, onRowClick]);

    useEffect(() => {
        if (contracts.length > 0 && !selectedRow) onRowClick(contracts[0]);
    }, [contracts, selectedRow, onRowClick]);

    useEffect(() => {
        setHasMore(true);
    }, [getQueryParams]);

    const handleScroll = () => {
        const wrapper = tableScrollAreaRef.current;
        if (!wrapper || !hasMore) return;

        const { scrollTop, scrollHeight, clientHeight, scrollLeft } = wrapper;
        setIsFixedHidden(scrollLeft > 50);

        if (scrollTop + clientHeight >= scrollHeight - 20) {
            if (monitoringDataloading !== "pending") {
                const offset = contracts.length;
                const queryParams = { ...getQueryParams(), offset, limit: DEFAULT_LIMIT };
                dispatch(fetchMonitoringData(queryParams)).then((res) => {
                    const fetched = res.payload?.data?.contracts || [];
                    if (fetched.length < DEFAULT_LIMIT) {
                        setHasMore(false);
                    }
                });
            }
        }
    };

    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
        } else {
            setSortField(field);
            setSortOrder("asc");
        }
    };

    if (monitoringDataloading === "pending" && contracts.length === 0) {
        return (
            <div className={styles.loaderContainer}>
                <Spinner />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.tableWrapper}>
                <div
                    className={styles.tableScrollArea}
                    ref={tableScrollAreaRef}
                    onScroll={handleScroll}
                >
                    <table className={styles.table}>
                        <thead>
                        <tr>
                            <th
                                className={`${styles.fixedColumn} ${
                                    isFixedHidden ? styles.hidden : ""
                                }`}
                            >
                                №
                            </th>
                            <th
                                className={`${styles.fixedColumn} ${
                                    isFixedHidden ? styles.hidden : ""
                                }`}
                            >
                                Название
                            </th>
                            <th
                                className={`${styles.fixedColumn} ${
                                    isFixedHidden ? styles.hidden : ""
                                }`}
                            >
                                Номер контракта
                            </th>
                            <th
                                className={`${styles.fixedColumn} ${
                                    isFixedHidden ? styles.hidden : ""
                                }`}
                                onClick={() => handleSort("rating")}
                                style={{ cursor: "pointer" }}
                            >
                                Рейтинг&nbsp;
                                <span style={{ fontSize: "0.8em" }}>▲▼</span>
                            </th>
                            <th>Регион</th>
                            <th
                                onClick={() => handleSort("price")}
                                style={{ cursor: "pointer" }}
                            >
                                Цена&nbsp;
                                <span style={{ fontSize: "0.8em" }}>▲▼</span>
                            </th>
                            <th
                                onClick={() => handleSort("date")}
                                style={{ cursor: "pointer" }}
                            >
                                Дата подписания&nbsp;
                                <span style={{ fontSize: "0.8em" }}>▲▼</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {sortedContracts.map((item, index) => (
                            <tr
                                key={index + 1}
                                onClick={() => onRowClick(item)}
                                className={
                                    selectedRow === item.contract_reg_num
                                        ? styles.selected
                                        : ""
                                }
                                style={{ cursor: "pointer" }}
                            >
                                <td className={styles.fixedColumn}>{index + 1}</td>
                                <td className={styles.fixedColumn}>
                                    <ContractNameCell
                                        contractName={item.contract_name}
                                        selected={selectedRow === item.contract_reg_num}
                                    />
                                </td>
                                <td className={styles.fixedColumn}>{item.contract_reg_num}</td>
                                <td
                                    className={styles.fixedColumn}
                                    style={{ color: getRatingColor(item.rating) }}
                                >
                                    {item.rating}%
                                </td>
                                <td>{item.cust_region}</td>
                                <td>{Number(item.contract_price).toLocaleString("ru-RU")}</td>
                                <td>
                                    {new Date(item.contract_sign_date).toLocaleDateString("ru-RU")}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {monitoringDataloading === "pending" && contracts.length > 0 && (
                        <div className={styles.appendLoader}>
                            <Spinner />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StickyTable;
