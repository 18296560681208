import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from "../../components/Layout/Layout.module.css";
import stylesMain from "./ScoringPage.module.css";
import CommonHeader from "../../components/Header/ComonHeader";
import { ListSubheader, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useClearAll } from "../../hook/useClearAll";
import { fetchOkpdUserData } from "../../service/reducers/userOkpdListSlice";
import { fetchRegionsLkData } from "../../service/reducers/regionsLkSlice";
import axios from "axios";
import { PESONALAREA } from "../../api/config";
import {
    resetRelatedINNs,
    resetSearchOrgINNINNs,
    resetSearchSuppINNINNINNs,
    resetSelectedOrganization,
    resetSelectedSuggestion,
    setGroupsRed,
    setRelatedINNs,
    setSelectedComboItem,
    setSelectedOrganization,
    setSelectedValue,
} from "../../service/reducers/organizationSlice";
import { clearPosition } from "../../service/reducers/searchSwticherSlice";
import { resetOkpdCode, setOkpdCode } from "../../service/reducers/selectedComboOkpd";
import { resetRegionCode, setRegionCode } from "../../service/reducers/selectedComboRegionSlice";
import StickyTable from "./StickyTable/StickyTable";
import ScoringPercentageAlerts from "./ScoringPercentageAlerts/ScoringPercentageAlerts";
import SelectedScoringSegments from "./SelectedScoringSegments/SelectedScoringSegments";
import { fetchMonitoringData } from "../../service/reducers/MonitoringSlice";
import ContractInfo from "./ContractInfo/ContractInfo";

export const ScoringPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const clearAll = useClearAll();
    const [isCMBoxFocused, setIsCMBoxFocused] = useState(false);
    const [displayValue, setDisplayValue] = useState("");
    const [isStickyVisible, setIsStickyVisible] = useState(false);
    const [selectedContract, setSelectedContract] = useState(null);
    const fetchPromiseRef = useRef(null);
    const selectedSegmentsRef = useRef(null);
    const DEFAULT_LIMIT = 100;

    const groups = useSelector((state) => state.organization.groups);
    const okpdListData = useSelector((state) => state.okpdUserData.okpdUserData) || [];
    const regionsLkData = useSelector((state) => state.regionsLkData.regionsLkData) || [];
    const selectedValue = useSelector((state) => state.organization.selectedValue);
    const searchSelectedObj = useSelector((state) => state.organization.selectedSuggestion);
    const selectedComboItem = useSelector((state) => state.organization.selectedComboItem);
    const togglePosition = useSelector((state) => state.searchSwitcher.position);
    const { start_date, end_date } = useSelector((state) => state.scoringSearchFilter);

    const getMonitoringQueryParams = useCallback(() => {
        const queryParams = { offset: 0, limit: DEFAULT_LIMIT, start_date, end_date };
        if (searchSelectedObj && searchSelectedObj.inn && togglePosition) {
            if (togglePosition === "supplier") {
                queryParams.cust_inns = [searchSelectedObj.inn];
            } else if (togglePosition === "customer") {
                queryParams.sup_inns = [searchSelectedObj.inn];
            }
        } else if (
            selectedComboItem &&
            Object.keys(selectedComboItem).length > 0 &&
            selectedComboItem.companies?.length
        ) {
            const inns = selectedComboItem.companies.map((company) => company.inn);
            if (selectedComboItem.type === "company_suppliers") {
                queryParams.sup_inns = inns;
            } else if (selectedComboItem.type === "company_customer") {
                queryParams.cust_inns = inns;
            }
        }
        return queryParams;
    }, [DEFAULT_LIMIT, start_date, end_date, searchSelectedObj, togglePosition, selectedComboItem]);

    useEffect(() => {
        const currentRef = selectedSegmentsRef.current;
        const observer = new IntersectionObserver(
            ([entry]) => setIsStickyVisible(!entry.isIntersecting),
            { threshold: 0 }
        );
        if (currentRef) observer.observe(currentRef);
        return () => {
            if (currentRef) observer.unobserve(currentRef);
        };
    }, []);

    useEffect(() => {
        setSelectedContract(null);
        const queryParams = getMonitoringQueryParams();
        queryParams.offset = 0;
        if (fetchPromiseRef.current) fetchPromiseRef.current.abort();
        fetchPromiseRef.current = dispatch(fetchMonitoringData(queryParams));
    }, [dispatch, getMonitoringQueryParams]);

    useEffect(() => {
        dispatch(fetchOkpdUserData());
        dispatch(fetchRegionsLkData());
    }, [dispatch]);

    useEffect(() => {
        setDisplayValue(selectedValue === "" ? "" : selectedValue);
    }, [selectedValue]);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await axios.get(`${PESONALAREA}/personal-area/me_group`, {
                    withCredentials: true,
                });
                dispatch(setGroupsRed(response.data));
            } catch (error) {
                console.error("Ошибка при загрузке данных групп", error);
            }
        };
        fetchGroups();
    }, [dispatch]);

    const handleChange = (event) => {
        const selectedVal = event.target.value;
        let selectedItem =
            groups.find((group) => group.id === selectedVal) ||
            okpdListData.find((item) => item.okpd_code === selectedVal) ||
            regionsLkData.find((region) => region.iso === selectedVal);
        let valueToStore = "";
        if (selectedItem) {
            dispatch(setSelectedComboItem(selectedItem));
            if (selectedItem.label) {
                valueToStore = selectedItem.label;
            } else if (selectedItem.okpd_code && selectedItem.okpd_name) {
                valueToStore = `${selectedItem.okpd_code} ${selectedItem.okpd_name}`;
            } else if (selectedItem.subjects) {
                valueToStore = selectedItem.subjects;
            }
            dispatch(setSelectedValue(valueToStore));
        } else {
            return;
        }
        dispatch(clearPosition());
        dispatch(resetSelectedOrganization());
        dispatch(resetRelatedINNs());
        dispatch(resetSearchOrgINNINNs());
        dispatch(resetSearchSuppINNINNINNs());
        dispatch(resetOkpdCode());
        dispatch(resetRegionCode());
        navigate(location.pathname, { replace: true });
        const isOkpd = okpdListData.some((item) => item.okpd_code === selectedVal);
        if (isOkpd) {
            dispatch(setOkpdCode(selectedVal));
        } else if (regionsLkData.some((region) => region.iso === selectedVal)) {
            dispatch(setRegionCode(selectedVal));
        } else {
            const selectedOrg = groups.find((group) => group.id === selectedVal);
            dispatch(setSelectedOrganization(selectedOrg));
            dispatch(setRelatedINNs(selectedVal));
        }
        dispatch(resetSelectedSuggestion());
        clearAll();
    };

    return (
        <div className={styles.mainContainer}>
            <CommonHeader title="Скоринг">
                <Select
                    MenuProps={{ disableScrollLock: true }}
                    value={displayValue}
                    displayEmpty
                    renderValue={(selected) =>
                        !selected ? (
                            <span style={{ color: "#8D96B2" }}>Выберите группу</span>
                        ) : (
                            selected
                        )
                    }
                    onChange={handleChange}
                    onFocus={() => setIsCMBoxFocused(true)}
                    onBlur={() => setIsCMBoxFocused(false)}
                    sx={{
                        width: "280px",
                        height: "42px",
                        backgroundColor: "var(--headerCmg-bg-color, #FFF)",
                        transition: "background-color 0.5s ease-in-out, color 0.5s ease-in-out",
                        borderRadius: "6px",
                        marginLeft: "32px",
                        border: isCMBoxFocused ? "1px solid #4A3DFD" : "none",
                        color: "var(--text-color, black)",
                        "& .MuiSelect-select": {
                            paddingLeft: "16px",
                            fontFamily: "Golos Regular",
                            fontSize: "14px",
                            color: "var(--text-color, black)",
                            transition: "background-color 0.5s ease-in-out",
                        },
                        "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                        "&:hover": {
                            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                        },
                        "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                        },
                        "& .MuiSvgIcon-root": {
                            transition: "color 0.5s ease-in-out",
                            color: "var(--text-color, black)",
                        },
                    }}
                >
                    <ListSubheader>Созданные группы</ListSubheader>
                    {groups
                        .filter(
                            (group) =>
                                group.id !== "00000000-0000-0000-0000-000000000000" &&
                                (group.companies?.length > 0 ||
                                    group.okpds?.length > 0 ||
                                    group.regions?.length > 0 ||
                                    group.contract_reg_nums?.length > 0)
                        )
                        .map((group) => (
                            <MenuItem key={group.id} value={group.id}>
                                {group.label}
                            </MenuItem>
                        ))}
                </Select>
            </CommonHeader>
            <div ref={selectedSegmentsRef}>
                <SelectedScoringSegments />
            </div>
            {isStickyVisible && (
                <div className={styles.fixedSticky}>
                    <SelectedScoringSegments />
                </div>
            )}
            <div className={stylesMain.mainContainer}>
                <StickyTable
                    key={JSON.stringify(getMonitoringQueryParams())}
                    onContractSelect={setSelectedContract}
                    getQueryParams={getMonitoringQueryParams}
                />
            </div>
            <div className={stylesMain.container}>
                <div className={stylesMain.contractInfo}>
                    <ContractInfo contractData={selectedContract} />
                </div>
                <div className={stylesMain.scoringPercentageAlerts}>
                    <ScoringPercentageAlerts contractData={selectedContract} />
                </div>
            </div>
        </div>
    );
};

export default ScoringPage;
