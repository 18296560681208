import React, { useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns';
import styles from '../../SearchPage/SearchSettingsMain/SettingsContainer.module.css';
import closeIco from '../../../common/icons/xButtonGrey.svg';
import shevron from '../../../common/icons/testChevron.svg';
import style from "../../../components/Modal/ModalContent/ModalContent.module.css";
import xButtonIco from "../../../common/icons/xButtonBlack.svg";
import xButtonIcoDark from "../../../common/icons/xButtonGrey.svg";
import { CheckboxGroup } from "../../SearchPage/SearchSettingsComponents/CheckBoxGroup/CheckBoxGroup";
import { DatePickerWrapper } from "../../SearchPage/SearchSettingsComponents/DatePickerSettings/DatePickerWrapper";
import { PriceInput } from "../../SearchPage/SearchSettingsComponents/PriceInput/PriceInput";
import { setStartDate, setEndDate } from '../../../service/reducers/scoringSearchFilterSlice';
import { fetchMonitoringData } from '../../../service/reducers/MonitoringSlice';

const DateAndNormativeFilter = ({ onClose, onSearchClick }) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState({});
    const switcher = useSelector(state => state.switcher.activeSwitch);
    const { start_date, end_date } = useSelector(state => state.scoringSearchFilter);
    const { searchSelectedObj, selectedComboItem } = useSelector(state => ({
        searchSelectedObj: state.organization.selectedSuggestion,
        selectedComboItem: state.organization.selectedComboItem,
    }));
    const togglePosition = useSelector(state => state.searchSwitcher.position);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState('');
    const startPickerRef = useRef();
    const endPickerRef = useRef();
    const DEFAULT_LIMIT = 100;

    const handleSearch = () => {
        const queryParams = { offset: 0, limit: DEFAULT_LIMIT, start_date, end_date };
        if (searchSelectedObj && searchSelectedObj.inn && togglePosition) {
            if (togglePosition === "supplier") {
                queryParams.cust_inns = [searchSelectedObj.inn];
            } else if (togglePosition === "customer") {
                queryParams.sup_inns = [searchSelectedObj.inn];
            }
        } else if (
            selectedComboItem &&
            Object.keys(selectedComboItem).length > 0 &&
            selectedComboItem.companies?.length
        ) {
            const inns = selectedComboItem.companies.map(company => company.inn);
            if (selectedComboItem.type === "company_suppliers") {
                queryParams.sup_inns = inns;
            } else if (selectedComboItem.type === "company_customer") {
                queryParams.cust_inns = inns;
            }
        }
        if (minPrice) queryParams.min_price = minPrice;
        if (maxPrice) queryParams.max_price = maxPrice;

        dispatch(fetchMonitoringData(queryParams));
        onSearchClick && onSearchClick();
        onClose();
    };

    const handlePriceChange = useCallback((e, setter, isMin) => {
        const value = e.target.value.replace(/\s/g, '');
        const number = value === '' ? (isMin ? 0 : 1000000) : Number(value);
        setter(number);
    }, []);

    const openDatePicker = useCallback((pickerRef) => {
        pickerRef.current.setOpen(true);
    }, []);

    const priceInputs = (
        <div className={`${styles.datePickerContainer} ${styles.dataPickerContainerSmG}`}>
            <PriceInput
                label="Минимальная, ₽"
                value={minPrice}
                onChange={(e) => handlePriceChange(e, setMinPrice, true)}
                placeholder="0"
            />
            <PriceInput
                label="Максимальная, ₽"
                value={maxPrice}
                onChange={(e) => handlePriceChange(e, setMaxPrice, false)}
                placeholder="любая"
            />
        </div>
    );

    const handleStartDateChange = (date) => {
        dispatch(setStartDate(format(date, 'yyyy-MM-dd')));
    };

    const handleEndDateChange = (date) => {
        dispatch(setEndDate(format(date, 'yyyy-MM-dd')));
    };

    const datePickerInputs = (
        <div className={styles.datePickerContainer}>
            <DatePickerWrapper
                label="Начиная с"
                date={new Date(start_date)}
                setDate={handleStartDateChange}
                pickerRef={startPickerRef}
                openDatePicker={openDatePicker}
            />
            <DatePickerWrapper
                label="По"
                date={new Date(end_date)}
                setDate={handleEndDateChange}
                pickerRef={endPickerRef}
                openDatePicker={openDatePicker}
                minDate={new Date(start_date)}
            />
        </div>
    );

    const checkboxGroups = {
        6: [
            { label: "Исполнено", key: "is44FZChecked", checked: false, onChange: () => {} },
            { label: "Исполнение завершено", key: "is223FZChecked", checked: false, onChange: () => {} },
            { label: "Исполнение Прекращено", key: "is223FZChecked", checked: false, onChange: () => {} },
            { label: "Анулировано", key: "is223FZChecked", checked: false, onChange: () => {} },
        ],
        8: [
            { label: "Цена контракта увеличилась", key: "isProvisionTrue", checked: false, onChange: () => {} },
            { label: "Цена контракта уменьшилась", key: "isProvisionFalse", checked: false, onChange: () => {} },
            { label: "Изменились условия", key: "isProvisionFalse", checked: false, onChange: () => {} },
        ],
    };

    const accordionContent = {
        0: datePickerInputs,
        1: priceInputs,
        2: (
            <div className={styles.checkBoxContainer}>
                <CheckboxGroup options={checkboxGroups[6]} />
            </div>
        ),
        3: (
            <div className={styles.checkBoxContainer}>
                <CheckboxGroup options={checkboxGroups[8]} />
            </div>
        )
    };

    const toggleAccordion = (index) => {
        setIsOpen(prevState => ({ ...prevState, [index]: !prevState[index] }));
    };

    const resetFilters = () => {
        setMinPrice(0);
        setMaxPrice('');
    };

    return (
        <div className={styles.container}>
            <div className={`${style.additionalMr} ${style.contentHeader}`}>
                <div>Расширенный поиск</div>
                <div className={style.closeXIcon} onClick={onClose}>
                    {switcher === 'sun' ? (
                        <img src={xButtonIco} alt="sunIcon" />
                    ) : (
                        <img src={xButtonIcoDark} alt="moonIcon" />
                    )}
                </div>
            </div>
            <div className={styles.filterSection}>
                {['Опубликовано', 'Цена', 'Статус', 'Алерты'].map((item, index) => (
                    <div key={index}>
                        <div className={styles.accordionHeader} onClick={() => toggleAccordion(index)}>
                            <img
                                src={shevron}
                                alt="toggle"
                                className={`${styles.accordionIcon} ${isOpen[index] ? styles.rotateIcon : ''}`}
                            />
                            <div className={styles.accordionHeaderTitle}>{item}</div>
                        </div>
                        <div className={`${styles.accordionContent} ${isOpen[index] ? styles.accordionOpen : ''}`}>
                            {accordionContent[index]}
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.footer}>
                <div className={styles.footerButtonClose} onClick={resetFilters}>
                    <img src={closeIco} alt="close" />
                    <div className={styles.footerBtnText}>Сбросить фильтры</div>
                </div>
                <button onClick={handleSearch} className={styles.searchButton}>Найти</button>
            </div>
        </div>
    );
};

export default DateAndNormativeFilter;
