import React, { useEffect } from 'react';
import style from "./SelectedScoringSegments.module.css";
import { useDispatch, useSelector } from "react-redux";
import xButtonIco from "../../../common/icons/xButtonGrey.svg";
import { useModal } from "../../../hook/useModal";
import { useClearAll } from "../../../hook/useClearAll";
import { cancelAllPendingRequests } from "../../../api/api";
import {
    resetRelatedINNs,
    resetSearchOrgINNINNs,
    resetSearchSuppINNINNINNs,
    resetSelectedComboItem,
    resetSelectedOrganization,
    resetSelectedSuggestion,
    resetSelectedValue,
    setRelatedINNs,
    setSelectedOrganization
} from "../../../service/reducers/organizationSlice";
import { clearPosition, setTogglePosition } from "../../../service/reducers/searchSwticherSlice";
import { resetOkpdCode } from "../../../service/reducers/selectedComboOkpd";
import { resetRegionCode } from "../../../service/reducers/selectedComboRegionSlice";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../api/config";
import ModalContent from "../../../components/Modal/ModalContent/ModalContent";
import ModalContentCompany from "../../../components/Modal/ModalContentCompany/ModalContentCompany";
import DateAndNormativeFilter from "../ModalFilter/DateAndNormativeFilter";
import FilterIconWithBadge from "../../../components/FilterComponent/FilterBadge";

const SelectedScoringSegments = () => {
    const { setIsModalVisible, setModalContent } = useModal();
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const selectedComboItem = useSelector(state => state.organization.selectedComboItem);
    const dispatch = useDispatch();
    const togglePosition = useSelector(state => state.searchSwitcher.position);
    const isToggleLocked = useSelector(state => state.searchSwitcher.isLocked);
    const clearAll = useClearAll();
    const defaultUID = "00000000-0000-0000-0000-000000000000";
    const navigate = useNavigate();
    const location = useLocation();

    const toggleShouldBeFixed =
        selectedComboItem &&
        Object.keys(selectedComboItem).length > 0 &&
        searchOrgINNINNs.length === 0 &&
        searchSuppINNINNINNs.length === 0;

    useEffect(() => {
        if (toggleShouldBeFixed) {
            const newPos = selectedComboItem.type === "company_customer" ? "supplier" : "customer";
            dispatch(setTogglePosition(newPos));
        }
    }, [dispatch, selectedComboItem, toggleShouldBeFixed]);

    const handleCloseClick = () => {
        cancelAllPendingRequests();
        dispatch(resetSearchOrgINNINNs());
        dispatch(resetSearchSuppINNINNINNs());
        dispatch(clearPosition());
        dispatch(resetSelectedSuggestion());
        clearAll();
        dispatch(setRelatedINNs(defaultUID));
        dispatch(setSelectedOrganization(defaultUID));
        navigate(location.pathname, { replace: true });
    };

    const handleComboClick = () => {
        cancelAllPendingRequests();
        dispatch(resetSelectedOrganization());
        dispatch(resetRelatedINNs());
        dispatch(resetSearchOrgINNINNs());
        dispatch(resetSearchSuppINNINNINNs());
        dispatch(resetSelectedComboItem());
        dispatch(resetSelectedValue());
        dispatch(resetOkpdCode());
        dispatch(resetRegionCode());
        dispatch(resetSelectedSuggestion());
        clearAll();
        dispatch(setRelatedINNs(defaultUID));
        dispatch(setSelectedOrganization(defaultUID));
        navigate(location.pathname, { replace: true });
    };

    const handleCompanyClick = async () => {
        if (displayValues.length > 0) {
            try {
                const response = await axios.get(`${BASE_URL}/card_company`, {
                    params: {
                        inn: displayValues[0]
                    },
                    withCredentials: true
                });

                const companyData = response.data;

                setModalContent(
                    <ModalContentCompany
                        onClose={() => setIsModalVisible(false)}
                        companyData={companyData}
                    />
                );
                setIsModalVisible(true);
            } catch (error) {
                console.error("Error fetching company data", error);
            }
        }
    };

    const handleComboClickModal = () => {
        setModalContent(
            <ModalContent
                onClose={() => setIsModalVisible(false)}
                contentMode="combo"
            />
        );
        setIsModalVisible(true);
    };
    const handleFilterClick = () => {
        setModalContent(
            <DateAndNormativeFilter
                onClose={() => setIsModalVisible(false)}
            />
        );
        setIsModalVisible(true);
    };

    let displayValues = [];
    if (searchOrgINNINNs.length > 0) {
        displayValues = searchOrgINNINNs;
    } else if (searchSuppINNINNINNs.length > 0) {
        displayValues = searchSuppINNINNINNs;
    }

    const handleCustomerClick = () => {
        if (!isToggleLocked && !toggleShouldBeFixed) {
            cancelAllPendingRequests();
            dispatch(setTogglePosition('customer'));
        }
    };

    const handleSupplierClick = () => {
        if (!isToggleLocked && !toggleShouldBeFixed) {
            cancelAllPendingRequests();
            dispatch(setTogglePosition('supplier'));
        }
    };

    const truncateString = (str, num) => {
        return str.length > num ? str.slice(0, num) + "..." : str;
    };

    return (
        <div className={`${style.container}`}>
            <div className={style.headerRow}>
                <div className={style.headerTitle}>
                    {Object.keys(selectedComboItem).length === 0 ? (
                        <div className={style.selected} onClick={handleCompanyClick}>
                            <span>
                                {displayValues.length > 0 ? (
                                    displayValues.map((value, index) => (
                                        <div key={index}>{value}</div>
                                    ))
                                ) : (
                                    "Название группы/ИНН/Название компании"
                                )}
                            </span>
                            {displayValues.length > 0 && (
                                <img
                                    src={xButtonIco}
                                    alt="xIcon"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleCloseClick();
                                    }}
                                />
                            )}
                        </div>
                    ) : (
                        <div onClick={handleComboClickModal} className={style.selected}>
                            <span>
                                {('okpd_name' in selectedComboItem && selectedComboItem.okpd_name)
                                    ? truncateString(selectedComboItem.okpd_name, 15)
                                    : ('label' in selectedComboItem && selectedComboItem.label)
                                        ? truncateString(selectedComboItem.label, 15)
                                        : ('subjects' in selectedComboItem && selectedComboItem.subjects)
                                            ? truncateString(selectedComboItem.subjects, 15)
                                            : "Название группы/ИНН/Название компании"}
                            </span>
                            <img
                                src={xButtonIco}
                                alt="xIcon"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleComboClick();
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className={style.lastChild}>
                    <FilterIconWithBadge
                        activeFilterCount={0}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleFilterClick();
                        }}
                    />
                    <div className={style.toggleContainer}>
                        <button
                            className={`${style.toggleButton} ${togglePosition === 'customer' ? style.toggleButtonActive : ''}`}
                            onClick={handleCustomerClick}
                            disabled={toggleShouldBeFixed}
                        >
                            Поставщик
                        </button>
                        <button
                            className={`${style.toggleButton} ${togglePosition === 'supplier' ? style.toggleButtonActive : ''}`}
                            onClick={handleSupplierClick}
                            disabled={toggleShouldBeFixed}
                        >
                            Заказчик
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectedScoringSegments;
