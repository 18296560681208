import React, { useState, useEffect } from "react";
import styles from "../../components/Layout/Layout.module.css";
import CommonHeader from "../../components/Header/ComonHeader";
import HeadSearchContent from "./HeadSearchContent/HeadSearchContent";
import SearchResults from "./SearchResult/SearchResult";
import { SearchModalProvider } from "../../hook/useSearchModal";
import LikeResultsComponent from "./LikeResults/LikeResultsComponent";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchSearchZData } from "../../service/reducers/SearchZSlice";
import { setParam } from "../../service/reducers/FilterSearchSlice";

export const TestSearch = () => {
    const [showLikeResults, setShowLikeResults] = useState(false);
    const [showLikedPurchases, setShowLikedPurchases] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [savedSearchName, setSavedSearchName] = useState('');
    const [hasSearchedOnMount, setHasSearchedOnMount] = useState(false);
    const likeResults = useSelector(state => state.likes.likeResults);
    const [iconClicked, setIconClicked] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const regnum = params.get("regnum");

        if (regnum) {
            setSearchText(regnum);
            dispatch(setParam({ key: "searchText", value: regnum }));
            dispatch(fetchSearchZData({ searchText: regnum }));
            navigate("/content/search", { replace: true });
        }
    }, [location, navigate, dispatch]);

    useEffect(() => {
        if (!location.search && !hasSearchedOnMount) {
            if (!searchText.trim()) {
                setSearchText("test");
                return;
            }
            handleSearchClick();
            setHasSearchedOnMount(true);
        } else {

        }
        // eslint-disable-next-line
    }, [location.search, searchText, hasSearchedOnMount]);

    const handleShowLikeResults = () => {
        setShowLikeResults(true);
        setShowLikedPurchases(false);
        setSavedSearchName('');
        setIconClicked(true);
    };

    const handleShowSearchResults = () => {
        setShowLikeResults(false);
        setShowLikedPurchases(false);
    };

    const handleShowLikedPurchases = () => {
        setShowLikeResults(false);
        setShowLikedPurchases(!showLikedPurchases);
        setSavedSearchName('');
        setIconClicked(true);
    };

    const handleInputChange = (value) => {
        setSearchText(value);
        setHasSearchedOnMount(false);
    };

    const handleOpenSearchResults = (name) => {
        setShowLikeResults(false);
        setShowLikedPurchases(false);
        setSavedSearchName(name);
        setIconClicked(false);
    };

    const handleSearchClick = () => {
        setSavedSearchName('');
        dispatch(setParam({ key: "searchText", value: searchText }));
        dispatch(fetchSearchZData({ searchText }));
    };

    return (
        <div className={styles.mainContainer}>
            <SearchModalProvider>
                <CommonHeader title="Поиск закупок" />
                <HeadSearchContent
                    isSavedSearches={showLikeResults}
                    onShowLikeResults={handleShowLikeResults}
                    onShowSearchResults={handleShowSearchResults}
                    onShowLikedPurchases={handleShowLikedPurchases}
                    onInputChange={handleInputChange}
                    inputValue={searchText}
                    showLikedPurchases={showLikedPurchases}
                    onSearchClick={handleSearchClick}
                    iconClickedProp={iconClicked}
                />
                {showLikeResults ? (
                    <LikeResultsComponent
                        likeResults={likeResults}
                        onReturnToSearch={handleShowSearchResults}
                        searchText={searchText}
                        onOpenSearchResults={handleOpenSearchResults}
                    />
                ) : (
                    <SearchResults
                        showLikedPurchases={showLikedPurchases}
                        onReturnToSearch={handleShowSearchResults}
                        searchText={searchText}
                        savedSearchName={savedSearchName}
                    />
                )}
            </SearchModalProvider>
        </div>
    );
};

export default TestSearch;

