import { combineReducers } from 'redux';
import productCodeReducer from './StackedBarChart/ProductCodeChartSlice';
import pieSliceReducer from './PieChart/PieChartSegmentSlice';
import menuReducer from './MenuSlice';
import pieChartSlice from "./PieChart/PieChartSlice";
import regionReducer from './GeoChart/GeoChartRegionSlice';
import activityChartSlice from "./ActivityChart/ActivityChartSlice";
import summaryDataSlice from "./Summary/SummarySlice";
import donutKbrChartSlice from "./KbrChart/DonutChartKbrSlice";
import activityDChartSlice from "./ActivityChart/ActivityChartSegmentSlice"
import geoChartSlice from "./GeoChart/GeoChartSlice"
import barWithLineChartSlice from "./PublishChart/BarWithLineChartSlice"
import lineChartSlice from "./LineChart/LineChartSlice"
import manyLineChartSlice from "./ManyLinesChart/ManyLineChartSlice"
import treeMapChartSlice from "./TreeMapChart/TreeMapChartSlice"
import donutRoleChartSlice from "./DonutChart/DonutChartRole"
import treeMapSegmentSlice from "./TreeMapChart/TreeMapChartSegmentSlice"
import barWithLineSegmentSlice from "./PublishChart/BarWithLineChartMonthSlice"
import organizationSlice from "./organizationSlice"
import tabsReducer from "./DiagrammConfig/TabSlice"
import diagramConfigSlice from "./DiagrammConfig/DiagramConfigSlice"
import contractOkpdChartSlice from "./StackedBarChart/Contracts/ContractOkpdChartSlice"
import contractBarWithLineSegmentSlice from "./PublishChart/Contracts/ContractBarWithLineChartMonthSlice"
import contractBarWithLineChartSlice from "./PublishChart/Contracts/ContractBarWithLineChartSlice"
import donutSegmentSliceV1 from "./DonutChart/DonutRoleSegmentSlice"
import indicatorChartSlice from "./IndicatorChartSlice"
import sunKeyChartSlice from "./SunKeyChartSlice"
import scatterChartSlice from "./BubbleChartSlice"
import sunBurstChartSlice from "./SunBurstChartSlice"
import ispOkpdChartSlice from "./StackedLineShort"
import switcherReducer from "./SwitcherSlice"
import legendSlice from "./legendItemsClick"
import infoSlice from "./InfoStatistics"
import colorsSlice from "./ColorsSlice"
import settingsTabsSlice from './SettingsTabsSlice'
import modalSlice from './ModalCloseStatusSlice'
import searchSlice from './testSearchSlice'
import privilegesSlice from './PrivelegiesSlice'
import searchSwitcher from './searchSwticherSlice'
import wizardSlice from './WizardSlice'
import usersData from './UsersDataSlice'
import meData from './MeSlice'
import formDataSlice from './formDataEditForms'
import organizationInfoData from './organizationInfoSlice'
import okpdSelectedDataSlice from './okpdTreeSelected'
import okpdUserData from './userOkpdListSlice'
import okpdComboSelect from './selectedComboOkpd'
import companyUserData from './soloCompanySlice'
import groupsData from './userGroupsSlice'
import dateSlice from './ChangeGlobalDatesSlice'
import searchFiltersSlice from './FilterSearchSlice'
import searchZSlice from './SearchZSlice'
import wizardVisibilitySlice from './WizardsSearchSlice'
import manyLineChartMonthSlice from './ManyLinesMonthSlice'
import donutKbrSegmentSlice from './KbrChart/DonutChartKbrSegmentSlice'
import segmentNameSlice from './ZoomableChartSegmentSlice'
import bubbleSegmentSlice from './BubbleSelectedSegmentSlice'
import zoomedDiagramSlice from './ZommedDSlice'
import regionsLkData from './regionsLkSlice'
import regionComboSelect from './selectedComboRegionSlice'
import likesSlice from './searchLikesSlice'
import purchaseLikesSlice from './purchaseLikesSlice'
import pieChartOtpSegmentSlice from './PierChartOtp/PieChartOtpSegmentSlice'
import pieChartOtpSlice from './PierChartOtp/PieChartOtpSlice'
import stackedWithContentSegmentSlice from './StackedWithContent/StackedWithContentSegmentSlice'
import stackedWithContentSlice from './StackedWithContent/StackedWithContentSlice'
import scatterNotificationChartSlice from './ScatterNotificationChartSlice'
import monitoringSlice from './MonitoringSlice'
import scoringSearchFilterSlice from "./scoringSearchFilterSlice";

const rootReducer = combineReducers({
    menu: menuReducer,
    productCode: productCodeReducer,
    pie: pieSliceReducer,
    pieChart: pieChartSlice,
    region: regionReducer,
    activityChart: activityChartSlice,
    summaryData: summaryDataSlice,
    kbrData: donutKbrChartSlice,
    donutKbrSegmentSlice: donutKbrSegmentSlice,
    activitySlice: activityDChartSlice,
    geoSlice: geoChartSlice,
    barWithLine: barWithLineChartSlice,
    lines: lineChartSlice,
    manyLines: manyLineChartSlice,
    treemap: treeMapChartSlice,
    treeMapSlice: treeMapSegmentSlice,
    respRole: donutRoleChartSlice,
    barLineChartMonth: barWithLineSegmentSlice,
    organization: organizationSlice,
    tabs: tabsReducer,
    dConfig: diagramConfigSlice,
    contractOkpd: contractOkpdChartSlice,
    contractMonth1Slice: contractBarWithLineSegmentSlice,
    contractMontChart: contractBarWithLineChartSlice,
    donutRolesSlice: donutSegmentSliceV1,
    indicator: indicatorChartSlice,
    sunKey: sunKeyChartSlice,
    scatter: scatterChartSlice,
    sunBurst: sunBurstChartSlice,
    ispOkpd: ispOkpdChartSlice,
    switcher: switcherReducer,
    legendItems: legendSlice,
    info: infoSlice,
    colorsData: colorsSlice,
    settingsTabs: settingsTabsSlice,
    modalStatus: modalSlice,
    search: searchSlice,
    privileges: privilegesSlice,
    searchSwitcher: searchSwitcher,
    wizardStep: wizardSlice,
    usersData: usersData,
    meData: meData,
    formDataSlice: formDataSlice,
    organizationInfoData: organizationInfoData,
    okpdSelectedDataSlice: okpdSelectedDataSlice,
    okpdUserData: okpdUserData,
    okpdComboSelect: okpdComboSelect,
    companyUserData: companyUserData,
    groupsData: groupsData,
    dateSlice: dateSlice,
    searchFiltersSlice: searchFiltersSlice,
    searchZSlice: searchZSlice,
    wizardVisibilitySlice: wizardVisibilitySlice,
    manyLineChartMonthSlice: manyLineChartMonthSlice,
    segmentNameSlice: segmentNameSlice,
    bubbleSegmentSlice: bubbleSegmentSlice,
    zoomedDiagramSlice: zoomedDiagramSlice,
    regionsLkData: regionsLkData,
    regionComboSelect: regionComboSelect,
    likes: likesSlice,
    purchaseLikes: purchaseLikesSlice,
    pieChartOtpSegment: pieChartOtpSegmentSlice,
    pieChartOtp: pieChartOtpSlice,
    stackedWithContentSegmentSlice: stackedWithContentSegmentSlice,
    stackedWithContentSlice: stackedWithContentSlice,
    scatterNotificationChartSlice: scatterNotificationChartSlice,
    monitoring: monitoringSlice,
    scoringSearchFilter: scoringSearchFilterSlice,
});

export default rootReducer;
