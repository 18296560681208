import React, { useEffect, useState } from "react";
import styles from "./ScoringPercentageAlerts.module.css";
import alertsList from "../../../utils/alertsList.json";
import Spinner from "../../TestPages/Spinner";
import { getRatingColor } from "../../../utils/colors";

const ScoringPercentageAlerts = ({ contractData }) => {
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        if (!contractData) return;

        const contractAlerts = alertsList.reduce((acc, alert) => {
            const value = contractData[alert.key];

            let conditionMet = false;
            if (typeof alert.expected === "string" && alert.expected.startsWith("НЕ_")) {
                const expectedVal = alert.expected.replace("НЕ_", "");
                conditionMet = value !== expectedVal;
            } else if (alert.comparison === "gte") {
                conditionMet = Number(value) >= Number(alert.expected);
            } else {
                conditionMet = value === alert.expected;
            }

            if (conditionMet) {
                acc.push({ name: alert.description });
            }
            return acc;
        }, []);

        setAlerts(contractAlerts);
    }, [contractData]);

    if (!contractData) return <Spinner />;

    return (
        <div className={styles.alertContainer}>
            <div className={styles.container}>
                <div className={styles.percentage}>
          <span className={styles.score}>
            ~{Math.round(contractData.rating || 0)}%
          </span>
                    <span className={styles.label}>Рейтинг контракта</span>
                    <div className={styles.progressBar}>
                        <div
                            className={styles.progress}
                            style={{
                                width: `${contractData.rating || 0}%`,
                                background: getRatingColor(contractData.rating || 0),
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={`${styles.container} ${styles.smVh}`}>
                {alerts.length > 0 ? (
                    <table className={styles.alertTable}>
                        <thead>
                        <tr>
                            <th>№</th>
                            <th>Название</th>
                        </tr>
                        </thead>
                        <tbody>
                        {alerts.map((alert, index) => (
                            <tr key={index}>
                                <td className={styles.redText}>{index + 1}</td>
                                <td className={styles.redText}>{alert.name}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <div>Для выбранного контракта, пока, нет алертов.</div>
                )}
            </div>
        </div>
    );
};

export default ScoringPercentageAlerts;
