import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { testMonitoring } from "../../api/api";

const initialState = {
    monitoringData: { data: { contracts: [] } },
    monitoringDataloading: 'idle',
    error: null,
};

export const fetchMonitoringData = createAsyncThunk(
    'monitoring/fetchMonitoringData',
    async (queryParams, { signal, rejectWithValue }) => {
        try {
            const response = await testMonitoring(queryParams, { signal });
            return response.data;
        } catch (err) {
            if (err.name === 'AbortError') {
                return rejectWithValue('Request cancelled');
            }
            throw err;
        }
    }
);

const monitoringSlice = createSlice({
    name: 'monitoring',
    initialState,
    reducers: {
        resetMonitoringData: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMonitoringData.pending, (state) => {
                state.monitoringDataloading = 'pending';
            })
            .addCase(fetchMonitoringData.fulfilled, (state, action) => {
                state.monitoringDataloading = 'successful';
                const { offset = 0 } = action.meta.arg;
                if (offset > 0) {
                    state.monitoringData.data.contracts = [
                        ...state.monitoringData.data.contracts,
                        ...action.payload.data.contracts,
                    ];
                } else {
                    state.monitoringData = action.payload;
                }
            })
            .addCase(fetchMonitoringData.rejected, (state, action) => {
                if (
                    action.payload === 'Request cancelled' ||
                    action.error.message === 'Request cancelled'
                ) {
                    state.monitoringDataloading = 'idle';
                } else {
                    state.monitoringDataloading = 'failed';
                    state.error = action.error.message;
                }
            });
    },
});

export const { resetMonitoringData } = monitoringSlice.actions;
export default monitoringSlice.reducer;
