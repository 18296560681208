import React, {useEffect, useRef, useMemo} from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import {useDispatch, useSelector} from "react-redux";
import {activeColors} from "../../../utils/colors";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import Legend from "../../../components/DiagrammLegend/Legend";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import {
    fetchDonutRoleData,
    setRoleMode,
    setshowCountRespRole
} from "../../../service/reducers/DonutChart/DonutChartRole";
import {formatCurrency} from "../../../utils/rubbleFunc";
import {
    addRoleChartName,
    clearRoleDonutSegments,
    toggleSegment
} from "../../../service/reducers/DonutChart/DonutRoleSegmentSlice";
import {useVirtualTooltipSize} from "../../../hook/useVirtualTooltipSize";
import styles from "../TestMapD/GeoChart.module.css";
import {setActiveLegendItem} from "../../../service/reducers/legendItemsClick";
import {cancelAllPendingRequests} from "../../../api/api";
import tooltipNames from "../../../utils/tooltipTitles.json"
import useFetchData from "../../../hook/useFetchData";
import useTooltip from "../../../hook/useTooltip";
import Tooltip from "../../../components/Tooltip/Tooltip";

const RoleDiagramm = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const { width, height } = useResizeObserver({ ref });
    const { RespRoleData, loading, showCountRespRole, roleMode } = useSelector((state) => state.respRole);
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);

    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const memoizedSelectedDonutSegmentsV1 = useMemo(() => selectedDonutSegmetsV1 || [], [selectedDonutSegmetsV1]);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    const regionComboSelect = useSelector((state) => state.regionComboSelect.regionComboData);
    const shouldShowChangeButton = (selectedOrganization.type === 'okpd' || selectedOrganization.type === 'region' || filterOkpd.length > 0 || regionComboSelect.length > 0) && activeTab !== 'Извещения';

    const getHeaderTitle = (activeTab, showCountRespRole, selectedOrganizationType) => {
        if (activeTab === 'Контракты' || activeTab === 'Исполнение') {
            if (selectedOrganizationType === 'company_customer' || roleMode === 'cust' || slidePoz === 'supplier') {
                return `Структура исполнителей по ОПФ ${showCountRespRole === 'count' ? "(от кол-ва)" : "(от суммы)"}`;
            } else if (selectedOrganizationType === 'company_suppliers' || roleMode === 'org' || slidePoz === 'customer') {
                return `Структура заказчиков по ОПФ ${showCountRespRole === 'count' ? "(от кол-ва)" : "(от суммы)"}`;
            }
        }
        switch (activeTab) {
            case 'Извещения':
                return `Структура ролей организации в закупках ${showCountRespRole === 'count' ? "(от кол-ва)" : "(от суммы)"}`;
            case 'Контракты':
                return `Структура поставщиков по ОПФ ${showCountRespRole === 'count' ? "(от кол-ва)" : "(от суммы)"}`;
            case 'Исполнение':
                return `Структура Исполнителей по ОПФ ${showCountRespRole === 'count' ? "(от кол-ва)" : "(от суммы)"}`;
            default:
                return 'Unknown Tab';
        }
    }

    const headerTitle = getHeaderTitle(activeTab, showCountRespRole, selectedOrganization.type);
    const headerIcons = [
        { name: 'zoom', icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
        { name: 'change', icon: icons.change, width: 20, height: 20 },
        { name: 'menu', icon: icons.menu, width: 20, height: 20 },
    ];

    const headerWithThreeButtons = {
        title: headerTitle,
        icons: headerIcons
    };

    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();

    useFetchData(fetchDonutRoleData, [
        useSelector(state => state.stackedWithContentSegmentSlice.selectedMonth),
        useSelector(state => state.pieChartOtpSegment.selectedSlice),
        useSelector(state => state.pie.selectedSlice),
        useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
        useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments),
        useSelector(state => state.okpdComboSelect.okpdComboData),
        useSelector(state => state.dateSlice.selectedDate),
        useSelector(state => state.productCode.selectedProduct),
        useSelector(state => state.region.activeRegions),
        useSelector(state => state.activitySlice),
        useSelector(state => state.productCode.trimCode),
        useSelector(state => state.treeMapSlice.selectedSegments),
        useSelector(state => state.barLineChartMonth.selectedMonth),
        useSelector(state => state.organization.relatedINNs),
        useSelector(state => state.contractOkpd.selectedOkpd),
        useSelector(state => state.contractOkpd.trimCode),
        useSelector(state => state.contractMonth1Slice.selectedContractMonth),
        useSelector(state => state.ispOkpd.selectedOkpd),
        useSelector(state => state.organization.searchOrgINNINNs),
        useSelector(state => state.organization.searchSuppINNINNINNs),
        useSelector(state => state.searchSwitcher.position),
        useSelector(state => state.respRole.roleMode)
    ], 'donutRole');

    useEffect(() => {
        if (loading === 'successful' && width && height && RespRoleData) {
            createDonutChart(RespRoleData);
        }
        // eslint-disable-next-line
    }, [width, height, RespRoleData, showCountRespRole, memoizedSelectedDonutSegmentsV1, loading]);

    const onSegmentClick = (event, d) => {
        cancelAllPendingRequests();
        onMouseOut();
        const segmentId = d.data.label;
        dispatch(toggleSegment(segmentId));
        dispatch(addRoleChartName(headerTitle));
        dispatch(setActiveLegendItem({ diagramId: headerTitle, activeItem: segmentId }));
    };

    const onLegendItemClick = (label) => {
        cancelAllPendingRequests();
        onMouseOut();
        dispatch(toggleSegment(label));
        dispatch(addRoleChartName(headerTitle));
    };

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const onMouseMove = (event, d) => {
        const mode = showCountRespRole === 'count' ? 'count' : 'sum';
        const commonConfig = tooltipNames['DonutRolesNotification']['Tabs'][activeTab]['common'];
        const specificConfig = tooltipNames['DonutRolesNotification']['Tabs'][activeTab][mode];
        let tooltipText = [];
        tooltipText.push({ label: commonConfig['name'], value: d.data.label });
        Object.entries(specificConfig).forEach(([key, russianKey]) => {
            if (key === "volume") {
                tooltipText.push({ label: russianKey, value: formatCurrency(d.data.value) });
            } else if (key === "quantity") {
                const countInfo = d.data.extra.find(x => x.label === 'count');
                tooltipText.push({ label: russianKey, value: countInfo ? `${countInfo.value} шт` : 'Н/Д' });
            }
        });

        let tooltipSize = calculateTooltipSize(tooltipText.map(item => `<div><strong>${item.label}</strong>: ${item.value}</div>`));
        let x = event.pageX + 10;
        let y = event.pageY + 10;
        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }
        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        showTooltip(event, tooltipText);
    };

    const onMouseOut = () => {
        hideTooltip();
    };

    const createDonutChart = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const svg = d3.select(ref.current).append("svg")
            .attr("width", width)
            .attr("height", height);
        let sortedData;
        let totalValue;
        const padAngle = 0.01;

        if (showCountRespRole === 'count') {
            totalValue = data.nodes.reduce((acc, cur) => acc + ((cur.extra && cur.extra[0].value) || 0), 0);
            sortedData = [...data.nodes].map(d => ({
                ...d,
                value: (d.extra && d.extra[0].value) || 0
            })).sort((a, b) => b.value - a.value);
        } else {
            totalValue = data.total;
            sortedData = [...data.nodes].sort((a, b) => b.value - a.value);
        }
        const radius = Math.min(width, height) * 0.35;
        const color = d3.scaleOrdinal().domain(sortedData.map(d => d.label)).range(activeColors);

        const pie = d3.pie().value(d => d.value).padAngle(0.01);
        const arcData = pie(sortedData);

        const fontSizeScale = d3.scaleLinear()
            .domain([100, 600])
            .range([10, 24]);

        const fontSize = fontSizeScale(radius);

        const arcGenerator = d3.arc()
            .innerRadius(radius * 0.7)
            .outerRadius(radius);

        const arcs = svg.append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`)
            .selectAll("path")
            .data(arcData)
            .enter()
            .append("path")
            .attr("fill", d => color(d.data.label))
            .attr("stroke", d => memoizedSelectedDonutSegmentsV1.includes(d.data.label) ? "black" : "none")
            .attr("stroke-width", d => memoizedSelectedDonutSegmentsV1.includes(d.data.label) ? 0.5 : 0)
            .attr("opacity", d => memoizedSelectedDonutSegmentsV1.length === 0 || memoizedSelectedDonutSegmentsV1.includes(d.data.label) ? 1 : 0.3)
            .on('mousemove', (event, d) => onMouseMove(event, d))
            .on('mouseout', onMouseOut)
            .on('click', (event, d) => onSegmentClick(event, d));

        arcs.transition()
            .duration(1000)
            .attrTween("d", arcTween(arcGenerator, padAngle));

        function arcTween(arcGenerator, padAngle) {
            return function(d) {
                const minSize = padAngle * 2;
                const i = d3.interpolate(d.startAngle + padAngle, Math.max(d.endAngle - padAngle, d.startAngle + minSize));
                return function(t) {
                    d.endAngle = i(t);
                    return arcGenerator(d);
                };
            };
        }

        let overlappingTexts = [];
        // eslint-disable-next-line
        const text = svg.append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`)
            .selectAll("text")
            .data(arcData)
            .enter()
            .append("text")
            .attr("font-size", `${fontSize}px`)
            .attr("font-family", "Golos Regular")
            .attr("transform", function(d) {
                const [x, y] = arcGenerator.centroid(d);
                return `translate(${x}, ${y})`;
            })
            .attr("text-anchor", "middle")
            .attr("fill", "white")
            .text(function(d) {
                const percentage = ((d.data.value / totalValue) * 100).toFixed(1);
                return percentage >= 4 ? `${percentage}%` : "";
            })
            .each(function(d) {
                const thisText = d3.select(this);
                thisText.text(`${((d.data.value / totalValue) * 100).toFixed(1)}%`);
                const bbox = thisText.node().getBBox();
                const textWidth = bbox.width;
                const angleDifference = d.endAngle - d.startAngle;
                const segmentWidth = angleDifference * radius;
                if (textWidth > segmentWidth) {
                    const [x, y] = arcGenerator.centroid(d);
                    thisText
                        .attr("transform", `translate(${x}, ${y - 20})`)
                        .attr("fill", "var(--text-color)");

                    overlappingTexts.push({
                        textElement: thisText,
                        value: d.data.value
                    });
                }
            });
        if (overlappingTexts.length > 1) {
            const maxText = d3.max(overlappingTexts, d => d.value);
            overlappingTexts.forEach(d => {
                if (d.value !== maxText) {
                    d.textElement.attr("visibility", "hidden");
                }
            });
        }
    };

    const legendData = (RespRoleData?.nodes || [])
        .map(node => ({
            ...node,
            value: showCountRespRole === 'count'
                ? (node.extra && node.extra[0].value) || 0
                : node.value,
        }))
        .sort((a, b) => b.value - a.value);

    return (
        <div className={`${style.container} ${memoizedSelectedDonutSegmentsV1.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "600px" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    onZoomClick={onZoomClick}
                    diagramName={headerTitle}
                    activeMode={shouldShowChangeButton ? (roleMode || 'cust') : showCountRespRole}
                    handleMenuItemClick={(mode) => {
                        if (shouldShowChangeButton) {
                            if (mode === 'customer?') {
                                dispatch(setRoleMode('cust'));
                                if (memoizedSelectedDonutSegmentsV1.length > 0) {
                                    dispatch(clearRoleDonutSegments());
                                }
                            } else if (mode === 'supplier?') {
                                dispatch(setRoleMode('org'));
                                if (memoizedSelectedDonutSegmentsV1.length > 0) {
                                    dispatch(clearRoleDonutSegments());
                                }
                            } else {
                                dispatch(setRoleMode("cust"));
                            }
                        } else {
                            if (mode === 'sum') {
                                dispatch(setshowCountRespRole('sum'));
                            } else if (mode === 'count') {
                                dispatch(setshowCountRespRole('count'));
                            }
                        }
                    }}
                    diagramType={shouldShowChangeButton ? "roleIsp" : ""}
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.header}>
                        <Legend
                            key={`legend-${showCountRespRole}`}
                            diagramId={headerTitle}
                            data={legendData}
                            dynamicRadius={zoomedDiagram ? 150 : 75}
                            activeColors={activeColors}
                            onLegendItemClick={onLegendItemClick}
                            selectedSlice={memoizedSelectedDonutSegmentsV1}
                        />
                    </div>
                    <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} ref={tooltipRef} />
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`} />
                </>
            )}
        </div>
    );
};

export default RoleDiagramm;
