export const activeColors = ['#4B72FB', '#AB70FF', '#FEB9BA', '#CAE5CF', '#77A9FF', '#A2C4FF','#C8A1FF','#E94F74','#0F2C93','#1B0ECB','#4437F4','#717FFD','#A39CFF', '#C8A1FF','#AB70FF','#9245FF',
'#6F25D9','#4A3DFD','#4B72FB','#77A9FF','#A2C4FF','#FEB9BA','#EA9FA0','#D18384','#B46869','#7AA181','#91BE99','#B0DBB8',
    '#CAE5CF','#BBC0D2','#8D96B2','#62687F','#494D58'];

export const threeColors = ['#4B72FB', '#AB70FF','#E94F74']
export const treeMapColors = ['#0F2C93','#1B0ECB','#4437F4','#717FFD','#A39CFF', '#C8A1FF','#AB70FF','#9245FF',
'#6F25D9','#4A3DFD','#4B72FB','#77A9FF','#A2C4FF','#FEB9BA','#EA9FA0','#D18384','#B46869','#7AA181','#91BE99','#B0DBB8',
'#CAE5CF','#BBC0D2','#8D96B2','#62687F','#494D58']

export const bubbleColors = ['#4B72FB','#A39CFF', '#C8A1FF','#AB70FF','#9245FF',
    '#6F25D9','#4A3DFD','#4B72FB','#77A9FF','#A2C4FF','#FEB9BA','#EA9FA0','#D18384','#B46869','#7AA181','#91BE99','#B0DBB8',
    '#CAE5CF','#BBC0D2','#8D96B2','#62687F','#494D58']

export const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
    };
};

export const interpolateColor = (start, end, factor) => {
    return Math.floor(start + (end - start) * factor);
};
export const getRatingColor = (rating) => {
    const worstHex = "#FD2828";
    const bestHex = "#50AB64";
    const worst = hexToRgb(worstHex);
    const best = hexToRgb(bestHex);
    const factor = Math.max(0, Math.min(rating / 100, 1));

    const r = interpolateColor(worst.r, best.r, factor);
    const g = interpolateColor(worst.g, best.g, factor);
    const b = interpolateColor(worst.b, best.b, factor);

    return `rgb(${r}, ${g}, ${b})`;
};
